a{
    color: #757575;
    &.active{
        color: #137E84!important;
    }
    span{
        font-weight: 600!important;
    }
}

.mobileMenu{
    display: flex;
    justify-content: flex-end;
    padding: 24px;
    @media(min-width: 880px){
        display: none;
    }
}
.MuiPaper-root{
    width: 40%!important;
}