.campaignsList{
	max-width: 980px;
  margin: 0 auto;
  padding: 40px;
	&-item{
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 16px;

		@media (min-width: 880px) {
				grid-template-columns: repeat(2, 1fr);
				gap: 16px;
		}
	}
}
