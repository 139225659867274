.searchBlock{
    display: flex;
    align-items: center;
    justify-content: center;
    button{
        margin-left: 20px;
        height: 55px;
        width: 120px;
    }
}
