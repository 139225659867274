.carouselCard{
    max-width: 900px;
    background-color: white;
    margin: 0 auto;
    padding: 24px;
    &-header{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 24px;
        a{
            color: #137E84
        }
    }
    &-footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__info{
            display: flex;
            align-items: center;
            gap: 6px;
            a{
                color:#137E84;
                font-weight: 600;
                font-size: 14px;
            }
        }
        &__action{
            button{
                appearance: none;
                background: red;
                border: none;
                padding: 8px 16px;
                border-radius: 40px;
                color: white;
                font-family: SantanderMicroText;
                font-size: 16px;

            }
        }

    }
}
