.popup{
    position: fixed;
    top: 50%;
    left: 50%;
    background: white;
    padding-bottom: 16px;
    z-index: 9999;
    display: flex;
    align-items: center;
    flex-direction: column;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    overflow: hidden;
    width: 90%;
    @media (min-width: 880px) {
        width: 700px;
        top: 50%;
        transform: translate(-50%, -50%);

    }
    &-header{
        width: 100%;
        height: 130px;
    }
    &-content{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        padding: 0 16px;
        position: relative;
        &__warning{
            position: absolute;
            min-width: 110px;
            height: 24px;
            font-weight: 400;
            border-top-right-radius: 6px;
            border-bottom-left-radius: 6px;
            margin-bottom: 9px;
            overflow: hidden;
            box-sizing: border-box;
            top: 24px;
            right: 50px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            padding: 4px 13px;
            background: #FFCC33;
            &.green{
                background: #008437;
                p{color: white;}
            }
            p{
             text-align: center;
             color: black;
             font-size: 12px;
            }
         }
        &__logo{
            width: 88px;
            height: 88px;
            margin-top: -40px;
            border-radius: 50%;
            overflow: hidden;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #e5e5e5;
            margin-bottom: 12px;
            img{
                width: 88px;
            }
        }
        &__details{
            h6{
                margin-bottom: 12px;
            }
            h5{
                margin-bottom: 4px;
            }
            a{
                text-decoration: none;
                color: #137E84;
                font-weight: 600;
            }
            &__intro{
                h5{
                    white-space: pre-wrap;
                }
            }
            &__conditions{
                white-space: pre-wrap;
            }
            &__extra{
                white-space: pre-wrap;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                padding: 8px 26px;
                background: #e2f1ff;
                border-radius: 4px;

                width: fit-content;
                margin: 32px auto;
                svg{
                    margin-right: 8px;
                }
            }
            .hidden-box{
                background-color: white;
                margin-bottom: 0;
            }

        }
    }
    .bannerInfo{
        font-weight: 600;
        margin-top: 40px;
    }
}