.campaign{
  max-width: 440px;
  height: 230px;
  box-sizing: border-box;
  background-color: white ;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;

  &-header{
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 50%;
    &__date{
      position: absolute;
      right: 0;
      min-width: 92px;
      width: fit-content;
      height: 24px;
      font-weight: 400;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 4px;
      margin-bottom: 9px;
      overflow: hidden;
      box-sizing: border-box;
      background-color: #008437;

      p{
        text-align: center;
        color: white;
        font-size: 12px;
      }
      &.warning{
        background: #FFCC33;
        &>div{
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          padding: 2px;
        }
        p{
          color: black;
        }

      }
    }

  }
  &-content{
    position: absolute;
    display: flex;
    left: 16px;
    align-items: flex-end;
    &__logo{
      width: 88px;
      height: 88px;
      border-radius: 50%;
      overflow: hidden;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #e5e5e5;
      &__container {
        position: relative;
      }
      img{
        width: 88px;
      }
    }
    &__info{
      margin-bottom: -6px;
      margin-left: 20px;
    }
    &__notification{
      display: inline;
      position: absolute;
      top: 0px;
      right: 4px;
      height: 20px;
      background-color: white;
      border-radius: 20px;
    }
  }
  &-description{
    width: 100%;
    height: 50%;
    display: flex;
    align-items: flex-end;
    p{
      padding: 0 16px 16px 16px;
      font-size: 14px;
    }
  }
}