.carousel{
    max-width: 900px;
    margin: 0 auto;
    background-color: white;
    padding: 0 24px;
    .slick-slider{
        &>svg{
            color: #137E84;
        }
    }
}

.slickSliderCarousel{
    height: fit-content;
}