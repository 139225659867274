.carouselItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  &-logo {
    background: white;
    width: 74px;
    height: 74px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eeecec;
    &__container {
      position: relative;
    }
    img {
      width: 100%;
    }
  }
  &-merchant {
    text-align: center;
  }
  &-subtitle {
    p {
        position: relative;
        z-index: 2;
      background: #008437;
      width: 56px;
      padding: 1px 2px;
      border-radius: 30px;
      text-align: center;
      color: white;
      margin-top: -10px;
      font-size: 11px;
      font-weight: 400;
    }
  }
  &-notification{
    display: inline;
    position: absolute;
    top: 0px;
    right: 4px;
    height: 20px;
    background-color: white;
    border-radius: 20px;
  }
  &-warning {
    width: 80px;
    height: 20px;
    font-weight: 400;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    margin-bottom: 9px;
    overflow: hidden;
    box-sizing: border-box;
    p {
      background: #ffcc33;

      text-align: center;
      color: black;
      font-size: 11px;
    }
  }
}
